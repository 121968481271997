.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 20rem;
    color: white;
    font-size: 15px;
    background: linear-gradient(45deg, #db9ddb, #7d4caf99, #800080, #ee8d6863);
}
  
  .container h1,
  .container p {
    text-align: center;
    margin: 0; 
  }

 /* ContactForm.css */

 .contact-us-form {
    width: 55%;
    margin: 2% 10%;
    border: 1px solid white;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgb(224 180 223);
}
  
.tab-container {
    display: flex;
    height: 59px;
    font-size: medium;
}
  
.tab {
    flex: 1 1;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.4s ease-in-out;
    line-height: 40px;
}
  
.tab.active {
    background: linear-gradient(256deg, #e0bde0, #bf08d399, #bf08d399, #e0bde0);
    box-shadow: 0 0 10px rgb(5 5 5 / 43%);
    text-decoration: underline;
}
  
  .form-container {
    display: flex;
    flex-direction: column;
  }
  
  .form {
    display: none;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .form.active {
    display: block;
  }
  
  .form input[type='text'],
  .form input[type='email'],
  .form input[type='number'],
  .form textarea {
    width: calc(100% - 22px);
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  .form button {
    background-color: purple;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    width: 15%;
    height: 31px;
}
  
  .form textarea {
    height: 100px;
    resize: vertical;
  }
  
  .form button {
    background-color: purple; /* Purple button color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .form button:hover {
    background-color: #9a8af7; /* Light purple button color on hover */
  }
  @media screen and (min-width: 600px) and (max-width: 1000px) {
    .contact-us-form{
        width: 70%;
    }
  }
  @media (max-width:600px){
    .contact-us-form{
        width: 80%;
    }
  }
  