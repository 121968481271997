/* Footer.css */
.footer {
    background-color: white;
    color: purle;
    padding: 20px 0;
    text-align: center;
    border: 2px solid white;
    box-shadow: inset 0px 0px 10px 0px rgb(226 183 227);
}
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer-content p{
    font-size: 18px;
    word-wrap: break-word; 
  }
  
  .social-links {
    margin-top: 10px;
  }
  
  .social-links a {
    color: #fff;
    font-size: 24px;
    margin: 0 10px;
  }
  .social-links svg {
    height: 24px;
    width: 36px;
}
.instagram{
    color: #c32aa3;
}
.youtube{
    columns: red;
}
.github{
    color: black;
}
.compName {
    color: purple;
}