.welcomeHeading{
    color: purple;
}
.word-transition{
    color: purple;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    font-size: 2.3rem;
}
.word-visible {
    opacity: 1;
}
.mainDiv {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns */
    gap: 20px;  /* Gap between columns */
    height: auto;
}

.welcomeBox {
    width: 80%;
}

.RandomCodeBaseImage {
    width: 80%;
}
.text {
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 32px;
    margin-top: 32px;
    font-size: 3.0rem;
}
.text2 {
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 32px;
    margin-top: 32px;
    font-size: 2.3rem;
}
.welcomeBlogs {
    font-size: 18px; /* Adjust font size as needed */
    margin-left: 32px;
    margin-top: 32px;
    text-align: justify; /* Justify the text */
}
.RandomCodeBaseImage img {
    box-shadow: 0 0 10px 5px rgb(6 19 231 / 52%);
    border-radius: 10px;
    width: 100%;
    height: 80%;
    margin-top: 55px;
    margin-left: 35px;
}
@media (min-width : 300px) and (max-width : 475px) {
.welcomeBox p{
  width:82%;
}
}

/* responsive css style */
@media (max-width: 998px) {
    .RandomCodeBaseImage{
        display: none;
    }
    .welcomeBox {
        grid-column: 1 / span 23;
        text-align: center;
        width: 100%;
    }
    .welcomeBox p{
      width:86%;
    }
    .card-container {
        display: flex;
        margin-top: 5%
      }
      .card{
          width: 70%;
          height:55rem;
      }
      .card img {
        width: 90%;
        height: 605;
        border-radius: 8px 8px 0 0;
      }
      .mainDiv {
        display: grid;
        grid-template-columns: 1fr 1fr; /* Two columns */
        gap: 20px;  /* Gap between columns */
        height: auto;
    }
}

/* styles.css */

/* Basic styles */
body, html {
    margin: 0;
    padding: 0;
  }
  
  .card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    text-align: center;
    z-index: 1;
  }
  
  .card {
    border: 2px solid rgb(247, 246, 246);
    border-radius: 17px;
    display: inline-block; /* Display cards in a line */
    vertical-align: top; /* Align cards to the top */
    margin: 10px;
    z-index: 2;
    box-shadow: 5px 5px 10px 5px rgba(194, 195, 207, 0.52);
      height:55rem
  }
  
  .card img {
    width: 80%;
    height: 605;
    border-radius: 15px;
    margin-top: 15px;
    border: 2px solid grey;
    box-shadow: 2px -2px 10px 5px rgb(235, 234, 235);
  }
  
  .card h2 {
    margin-top: 20px;
    font-size: 2.1em;
  }
  
  .card p {
    margin-top: 10px;
    font-size: medium;
    text-align: justify;
    width: 93%;
    margin-left: 3%;
}
  
  .card button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: purple;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 15px;
    transition: transform 0.3s ease-in-out;
  }
  .card button:hover {
    transform: scale(1.1);
  }
  
  .card-container h3 {
    margin-bottom: 20px;
  }
  
   @media screen and (min-width: 1250px) {
    .card-container {
      display: block;
      margin-top: 3%;
    }
    .card{
        width: 30%;
    }
    .card-container h3 {
        margin-bottom: 20px;
      }
}

   @media screen and (min-width: 998px ) and (max-width: 1235px) {
    .card-container {
      display: block;
      margin-top: 3%
    }
    .card{
        width: 30%;
    }
}
h2 {
    font-size: 30px;
    margin-bottom: 3%;
}


/* Testimonial Carousel CSS */
/* Testimonial.css */
.testimonial-container {
    width: 93%;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 22px;
    text-align: center;
    border: 2px solid white;
    box-shadow: 5px 5px 10px 5px rgba(194, 195, 207, 0.52);
    padding: 30px;
}
  
  .testimonial-slider {
    margin-top: 20px;
    width: 100%; /* Adjust the width of the slider */
    position: relative;
  }
  
  .control-button {
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;
    border-radius: 5px;
    background-color: #333;
    color: white;
    margin: 0 5px; /* Adjust spacing between buttons */
    position: absolute; /* Position buttons absolutely within the slider */
    top: 50%; /* Position buttons at 50% from the top */
    transform: translateY(-50%); /* Center buttons vertically */
  }
  
  .prev {
    left: 10px; /* Adjust left position for previous button */
  }
  
  .next {
    right: 10px; /* Adjust right position for next button */
  }
  .testimonial-item {
    border: 2px solid #ffffff;
    width: 60%;
    margin-left: 20%;
    height: auto;
}
  
  /* Mobile responsiveness */
  @media (max-width: 998px) {
    .testimonial-container{
        width: 71%;
    }
    
    .testimonial-slider {
      flex-direction: column;
      align-items: stretch;
    }
  
    .control-button {
      width: calc(50% - 20px);
      margin-top: 10px;
      position: static; /* Change position to static for mobile */
      transform: translateY(0); /* Reset transform */
      top: 0; /* Reset top position */
    }
    .testimonial-item {
        border: 2px solid #ffffff;
        width: 60%;
        margin-left: 20%;
        height: auto;
    }
  
    .prev,
    .next {
      position: static;
      width: 100%;
    }
    .testimonial-item p {
        font-size: 14px;
    }
    button.slick-arrow.slick-next {
        width: 8% !important;
    }
    button.slick-arrow.slick-prev {
        width: 8% !important 
    }
  }
  button.slick-arrow.slick-next {
    background-color: purple;
    height: 6rem;
    border: 2px solid purple;
    width: 2%;
    border-radius: 16px;
    z-index: 1;
}

button.slick-arrow.slick-prev {
    background-color: purple;
    height: 6rem;
    border: 2px solid purple;
    width: 2%;
    border-radius: 16px;
    z-index: 1;
}
.testimonial-item p {
    font-size: 14px;
}
.testimonial-item svg {
    width: 26px;
    height: 26px;
}
  
  
